import * as ReactRouter from 'react-router-dom'
import * as ReactRedux from 'react-redux'
import React from 'react'

import * as Player from './player'
import { useSafeUpdateQuery } from './use-safe-update-query'

/**
 * A hook to enforce opening of the phone verification if it is required
 * for the user.
 */
export function usePhoneVerificationEnforcing() {
  const history = ReactRouter.useHistory()
  const isPhoneVerificationRequired = ReactRedux.useSelector(state =>
    Player.isPhoneVerificationRequired(state.player)
  )

  const safeQuery = useSafeUpdateQuery({
    'phone-verification': 'me',
    login: null,
  })

  const closeSafeQuery = useSafeUpdateQuery({
    'phone-verification': null,
  })

  React.useEffect(() => {
    if (isPhoneVerificationRequired) {
      history.push({
        search: safeQuery,
      })
    } else {
      history.push({
        search: closeSafeQuery,
      })
    }
  }, [isPhoneVerificationRequired])
}
