import * as React from 'react'
import * as PropTypes from 'prop-types'

import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'

export function CustomFieldError(props) {
  const i18n = I18n.useI18n()

  return (
    <Common.Box display="grid" gridGap="4px" wordBreak="break-word" pt="4px">
      <Common.Text fontSize={1} color="danger">
        {i18n.translate(props.translationKey)}
      </Common.Text>
    </Common.Box>
  )
}

CustomFieldError.propTypes = {
  translationKey: PropTypes.string,
}
