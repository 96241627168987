import * as R from 'ramda'
import * as React from 'react'
import PropTypes from 'prop-types'

import * as common from '@rushplay/common'
import * as forms from '@rushplay/forms'
import { lazy as loadable } from '@loadable/component'
import { useI18n } from '@rushplay/i18n'

import * as Constants from './constants'
import { CustomFieldError } from './custom-field-error'
import { FieldLabel } from './field-label'
import { Input } from './input'
import { getFieldNormalizer } from './get-field-normalizer'
import { getFieldParser } from './get-field-parser'
import { useDebouncedState } from './use-debounce-state'
import { usePrev } from './use-prev'

const FieldResponse = loadable(() => import('./field-response'))

export function InputField(props) {
  const i18n = useI18n()
  const form = forms.useFormContext()
  const field = forms.useField(props.scope, {
    initialValue: props.initialValue,
    normalize: getFieldNormalizer(props.format, props.normalize),
    parse: getFieldParser(props.format, props.parse),
  })

  const prevValue = usePrev(field.value)
  const [showError, setShowError, setShowErrorImmediate] = useDebouncedState(
    1000,
    false
  )
  const visited = ![
    forms.FieldStatus.PRISTINE,
    forms.FieldStatus.ABSENT,
  ].includes(field.status)

  function handleChange(e) {
    if (props.customErrorKey) {
      props.onClearCustomErrorKey()
    }
    field.onChange(e)
  }

  React.useEffect(() => {
    if (field.errors?.length === 0) {
      setShowErrorImmediate(false)
    } else if (!field.value && visited) {
      setShowErrorImmediate(true)
    } else {
      if (prevValue !== field.value) {
        setShowErrorImmediate(false)
      }
      setShowError(Boolean(field.value))
    }
  }, [field.value, field.errors, visited, prevValue])

  return (
    <common.Flex flexDirection="column">
      {i18n.hasTranslation(field.label) && (
        <FieldLabel disabled={props.disabled} htmlFor={field.name}>
          {i18n.translate(field.label)}
        </FieldLabel>
      )}
      <Input
        visited={
          !R.includes(field.status, [
            forms.FieldStatus.PRISTINE,
            forms.FieldStatus.ABSENT,
          ])
        }
        invalid={showError || Boolean(props.customErrorKey)}
        valid={field.errors?.length === 0 && !props.customErrorKey}
        autoComplete={props.autoComplete}
        autoCorrect={props.autoCorrect}
        autoFocus={props.autoFocus}
        currency={props.currency}
        disabled={props.disabled}
        inputMode={props.inputMode}
        maxLength={props.maxLength}
        minLength={props.minLength}
        name={field.name}
        id={`${form.name}-${field.name}`}
        prependIcon={props.prependIcon}
        placeholder={
          i18n.hasTranslation(field.placeholder)
            ? i18n.translate(field.placeholder)
            : ''
        }
        type={props.type}
        value={field.value}
        onChange={handleChange}
        onBlur={field.onBlur}
      />

      {props.suppressVisualFeedback ||
      props.hideFieldResponse ? null : props.customErrorKey ? (
        <CustomFieldError translationKey={props.customErrorKey} />
      ) : (
        <FieldResponse scope={props.scope} />
      )}
    </common.Flex>
  )
}

InputField.defaultProps = {
  disabled: false,
  type: 'text',
  normalize: value => value || undefined,
}

InputField.propTypes = {
  autoFocus: PropTypes.bool,
  autoComplete: PropTypes.string,
  autoCorrect: PropTypes.oneOf(['on', 'off']),
  currency: PropTypes.oneOf(Constants.CurrencyCodes),
  customErrorKey: PropTypes.string,
  disabled: PropTypes.bool,
  format: PropTypes.string,
  hideFieldResponse: PropTypes.bool,
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  inputMode: PropTypes.string,
  maxLength: PropTypes.string,
  minLength: PropTypes.string,
  normalize: PropTypes.func,
  parse: PropTypes.func,
  prependIcon: PropTypes.element,
  scope: PropTypes.string,
  suppressVisualFeedback: PropTypes.bool,
  type: PropTypes.oneOf(['text', 'password']),
  onClearCustomErrorKey: PropTypes.func,
}
