// https://developer.comm100.com/docs/visitor-side-api-get-started

export const comm100 = (() => {
  let options = null
  if (typeof window !== 'undefined') {
    window.Comm100API.onReady = function() {
      options = {
        openChatWindow: function() {
          const campaignIds = window.Comm100API.get('livechat.campaignIds')
          window.Comm100API.do('livechat.button.click', campaignIds[0])
        },
        toggleChatButtonVisibility: function(isVisible) {
          window.Comm100API.set('livechat.button.isVisible', isVisible)
        },
      }
    }
  }

  return {
    openChatWindow: () => options && options.openChatWindow(),
    openChatWindow2: () => options && options.openChatWindow2(),
    toggleChatButtonVisibility: isVisible =>
      options && options.toggleChatButtonVisibility(isVisible),
  }
})()
