import * as React from 'react'
import * as ReactRouter from 'react-router-dom'

import { comm100 } from './support-chat-api'

export function useToggleChatButtonVisibility() {
  const location = ReactRouter.useLocation()
  const shouldHideButton =
    location?.pathname?.includes('/sport') ||
    location?.search?.includes('referrer')

  React.useEffect(() => {
    if (shouldHideButton) {
      comm100?.toggleChatButtonVisibility(false)
    } else {
      comm100?.toggleChatButtonVisibility(true)
    }
  }, [shouldHideButton])
}
