import * as R from 'ramda'
import * as ReactRouter from 'react-router-dom'
import querystring from 'querystring'

/**
 * Only updates specified queries
 * @param {Object} location
 * @param {Object} nextQuery
 * @returns {string} querystring with updated values
 */
export function getSafeUpdateQuery(location, nextQuery) {
  const prevQuery = querystring.parse(R.drop(1, location.search))

  // close previous drawer before open new drawer to prevent overlaping
  // eslint-disable-next-line no-unused-vars
  const { ...updatedQuery } = prevQuery

  return querystring.stringify(
    R.reject(R.isNil, { ...updatedQuery, ...nextQuery })
  )
}

export function useSafeUpdateQuery(nextQuery) {
  const location = ReactRouter.useLocation()

  return getSafeUpdateQuery(location, nextQuery)
}
