import * as R from 'ramda'
import * as redux from 'redux'
import effects from 'redux-effects'
import fetch from 'redux-effects-fetch'
import multi from 'redux-multi'
import { composeWithDevTools } from 'redux-devtools-extension'

import * as analytics from '@rushplay/analytics'
import * as gtm from '@rushplay/analytics/gtm'
import * as maps from '@rushplay/analytics/maps'
import * as api from '@rushplay/api-client'
import * as casino from '@rushplay/casino'
import * as i18n from '@rushplay/i18n'
import * as notifications from '@rushplay/notifications'
import * as offers from '@rushplay/offers'
import * as processes from '@rushplay/processes'
import * as websockets from '@rushplay/websockets'
import * as jurisdiction from '@rushplay/compliance/jurisdiction'
import * as limits from '@rushplay/compliance/limits'
import * as realityCheck from '@rushplay/compliance/reality-check'

import * as Agent from './agent'
import * as Configuration from './configuration'
import * as Cookies from './cookies'
import * as Constants from './constants'
import * as GameCatalog from './game-catalog'
import * as Http from './http'
import * as Inventory from './inventory'
import * as PickedGames from './picked-games'
import * as Player from './player'
import * as Promotions from './promotions'
import * as Session from './session'
import * as Analytics from './analytics'

/**
 * Remove parts of state due to performance/security reasons
 */
const stateSanitizer = R.pipe(
  // Remove i18n state for speed up
  R.dissocPath(['i18n'])
)

export function createStore(options = {}) {
  return redux.createStore(
    redux.combineReducers({
      analytics: analytics.reducer,
      api: api.reducer,
      casino: casino.reducer,
      configuration: Configuration.reducer,
      gameCatalog: GameCatalog.reducer,
      http: Http.reducer,
      i18n: i18n.reducer,
      inventory: Inventory.reducer,
      jurisdiction: jurisdiction.reducer,
      limits: limits.reducer,
      notifications: notifications.reducer,
      offers: offers.reducer,
      pickedGames: PickedGames.reducer,
      player: Player.reducer,
      processes: processes.reducer,
      promotions: Promotions.reducer,
      realityCheck: realityCheck.reducer,
      session: Session.reducer,
    }),
    options.initialState,
    composeWithDevTools({ stateSanitizer })(
      redux.applyMiddleware(
        multi,
        websockets.middleware({
          bindings: {
            [websockets.SESSION_EXPIRED]: () => {
              return [
                Session.update(),
                Cookies.remove(
                  Constants.CookieKeys.IS_PHONE_VERIFICATION_REQUIRED
                ),
                notifications.add({
                  message: 'session-expired',
                  level: 'info',
                }),
              ]
            },
            [websockets.SHUFTI_PRO_UPLOAD_FEEDBACK]: data =>
              notifications.add({
                message: data.validUpload
                  ? `my-identity.status.verification.${data.lastUploadedDocumentType}.success`
                  : `my-identity.status.verification.${data.lastUploadedDocumentType}.failure`,
                level: data.validUpload ? 'success' : 'error',
                variables: {
                  declinedReasons: data.declinedReasons
                    ? `
                    <ul>
                      ${R.map(
                        reason => `<li>${reason}</li>`,
                        data.declinedReasons
                      )}
                    </ul>
                  `
                    : null,
                },
              }),
          },
        }),
        i18n.middleware(),
        api.middleware({
          countryCodeSelector: state =>
            Configuration.getCountryCode(state.configuration),
          defaultCacheFor: process.browser
            ? // CLIENT: No caching by default, needs to be set to 1 ms as 0
              // disables caching hence causes requests on hydration
              1
            : // SERVER: Roughly the average time to load page on client
              // completetly. This ensures we reuse cache from server requests,
              // but let client fetch fresh data if needed.
              30000,
          host: state => Configuration.getApiUrl(state.configuration),
          substateSelector: state => state.api,
          tokenSelector: state => Session.getToken(state.session),
          onBeforeFailure(response, next) {
            if (R.pathEq(['value', 'message'], 'session-expired', response)) {
              return Session.invalidate()
            }

            return next()
          },
        }),
        Agent.middleware,
        effects,
        fetch,
        Cookies.middleware(options.cookies),
        analytics.eventQueuingMiddleware({
          events: [Analytics.gtmEvents, Analytics.mapsEvents],
          queueSelector: state => analytics.getEventsQueue(state.analytics),
          onError: error => {
            if (process.browser && window.onerror) {
              window.onerror(error)
            }
          },
        }),
        gtm.middleware(Analytics.gtmEvents),
        maps.middleware(Analytics.mapsEvents, {
          endpoint: 'https://creatives.heropartners.io/masterpostback.aspx',
          key: 'so9kmdauot7syi2x',
          reportId: '2',
        })
      )
    )
  )
}
