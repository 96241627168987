import { createTheme } from './create-theme'

const STATIC_COLORS = [
  { name: 'static-black', value: 'hsl(0, 0%, 0%)' },
  { name: 'static-white', value: 'hsl(0, 0%, 100%)' },
]

const BASE_COLORS = [
  { name: 'base-50', value: 'hsl(210, 36%, 9%)' },
  { name: 'base-75', value: 'hsl(210, 70%, 5%)' },
  { name: 'base-100', value: 'hsl(210, 62%, 7%)' },
  { name: 'base-200', value: 'hsl(207, 47%, 15%)' },
  { name: 'base-300', value: 'hsla(209, 40%, 12%, 1)' },
  { name: 'base-400', value: 'hsl(210, 40%, 23%)' },
  { name: 'base-500', value: 'hsl(210, 35%, 30%)' },
  { name: 'base-600', value: 'hsl(210, 20%, 55%)' },
  { name: 'base-650', value: 'hsl(210, 5%, 52%)' },
  { name: 'base-700', value: 'hsl(210, 10%, 60%)' },
  { name: 'base-800', value: 'hsl(210, 5%, 80%)' },
  { name: 'base-900', value: 'hsl(210, 1%, 98%)' },
  { name: 'base-1000', value: 'hsl(210, 1%, 100%)' },

  { name: 'negative-50', value: 'hsl(6, 77%, 45%)' },
  { name: 'negative-75', value: 'hsl(6, 77%, 47%)' },
  { name: 'negative-100', value: 'hsl(6, 79%, 48%)' },
  { name: 'negative-200', value: 'hsl(6, 85%, 49%)' },
  { name: 'negative-300', value: 'hsl(6, 90%, 55%)' },
  { name: 'negative-400', value: 'hsl(6, 95%, 63%)' },
  { name: 'negative-500', value: 'hsl(6, 90%, 68%)' },
  { name: 'negative-600', value: 'hsl(6, 90%, 73%)' },
  { name: 'negative-700', value: 'hsl(6, 90%, 80%)' },
  { name: 'negative-800', value: 'hsl(6, 85%, 85%)' },
  { name: 'negative-900', value: 'hsl(6, 80%, 90%)' },
  { name: 'negative-1000', value: 'hsl(6, 75%, 95%)' },

  { name: 'positive-50', value: 'hsl(205, 100%, 8%)' },
  { name: 'positive-75', value: 'hsl(205, 100%, 10%)' },
  { name: 'positive-100', value: 'hsl(205, 100%, 15%)' },
  { name: 'positive-200', value: 'hsl(205, 100%, 20%)' },
  { name: 'positive-300', value: 'hsl(205, 100%, 24%)' },
  { name: 'positive-400', value: 'hsl(205, 99%, 26%)' },
  { name: 'positive-500', value: 'hsl(205, 100%, 50%)' },
  { name: 'positive-600', value: 'hsl(205, 95%, 35%)' },
  { name: 'positive-700', value: 'hsl(205, 60%, 45%)' },
  { name: 'positive-800', value: 'hsl(205, 50%, 60%)' },
  { name: 'positive-900', value: 'hsl(205, 45%, 70%)' },
  { name: 'positive-1000', value: 'hsl(205, 35%, 90%)' },

  { name: 'warning-50', value: 'hsl(41, 100%, 20%)' },
  { name: 'warning-75', value: 'hsl(41, 100%, 27%)' },
  { name: 'warning-100', value: 'hsl(41, 100%, 35%)' },
  { name: 'warning-200', value: 'hsl(41, 100%, 45%)' },
  { name: 'warning-300', value: 'hsl(41, 100%, 55%)' },
  { name: 'warning-400', value: 'hsl(41, 100%, 67%)' },
  { name: 'warning-500', value: 'hsl(41, 100%, 70%)' },
  { name: 'warning-600', value: 'hsl(41, 100%, 75%)' },
  { name: 'warning-700', value: 'hsl(41, 95%, 80%)' },
  { name: 'warning-800', value: 'hsl(41, 85%, 80%)' },
  { name: 'warning-900', value: 'hsl(41, 80%, 85%)' },
  { name: 'warning-1000', value: 'hsl(41, 70%, 93%)' },

  { name: 'info-50', value: '#FAFCFF' },
  { name: 'info-75', value: 'hsl(183, 90%, 20%)' },
  { name: 'info-100', value: 'hsl(183, 90%, 30%)' },
  { name: 'info-200', value: 'hsl(183, 85%, 35%)' },
  { name: 'info-300', value: 'hsl(183, 80%, 40%)' },
  { name: 'info-400', value: 'hsl(183, 72%, 43%)' },
  { name: 'info-500', value: 'hsl(183, 60%, 55%)' },
  { name: 'info-600', value: 'hsl(183, 55%, 60%)' },
  { name: 'info-700', value: 'hsl(183, 55%, 65%)' },
  { name: 'info-800', value: 'hsl(183, 50%, 70%)' },
  { name: 'info-900', value: 'hsl(183, 45%, 80%)' },
  { name: 'info-1000', value: 'hsl(183, 35%, 90%)' },
]

// These colors are very specific. Can't be found in current palettes
const COMPONENT_COLORS = [
  {
    name: 'game-info-missing-image-gradient-start',
    value: 'hsl(235, 76%, 56%)',
  },
  { name: 'loyalty-progress-bar-gradiant-end', value: 'hsl(305, 57%, 45%)' },
  { name: 'my-profile-background', value: 'hsl(209, 40%, 12%)' },
  { name: 'page-gradient-start', value: 'hsl(235, 76%, 56%)' },
  { name: 'page-gradient-middle', value: 'hsl(262, 54%, 48%)' },
  { name: 'loyalty-progress-bar-gradiant-start', value: 'hsl(42, 100%, 74%)' },
]

export default createTheme({
  breakpoints: [
    { name: 'sm', value: '800px' },
    { name: 'md', value: '1000px' },
    { name: 'lg', value: '1200px' },
  ],

  colors: [
    ...STATIC_COLORS,
    ...BASE_COLORS,
    ...COMPONENT_COLORS,

    // ALIASES
    { __type: 'alias', name: 'badge-background', value: 'warning-400' },
    { __type: 'alias', name: 'button-text-disabled', value: 'base-800' },
    { __type: 'alias', name: 'button-text', value: 'base-1000' },
    { __type: 'alias', name: 'card-background', value: 'base-75' },
    { __type: 'alias', name: 'card-text', value: 'static-white' },
    {
      __type: 'alias',
      name: 'cookie-banner-background',
      value: 'static-white',
    },
    { __type: 'alias', name: 'checkbox-checked', value: 'positive-500' },
    {
      __type: 'alias',
      name: 'cookie-banner-background',
      value: 'static-white',
    },
    { __type: 'alias', name: 'danger', value: 'negative-400' },
    { __type: 'alias', name: 'dashboard-edit-button', value: 'base-75' },
    { __type: 'alias', name: 'disabled', value: 'base-800' },
    { __type: 'alias', name: 'g-bg', value: 'base-50' },
    { __type: 'alias', name: 'g-text', value: 'info-50' },
    {
      __type: 'alias',
      name: 'failed-loading-casino-background',
      value: 'base-100',
    },
    {
      __type: 'alias',
      name: 'featured-label-background',
      value: 'base-300',
    },
    {
      __type: 'alias',
      name: 'featured-label-text',
      value: 'base-900',
    },
    { __type: 'alias', name: 'game-tile-background', value: 'base-75' },
    {
      __type: 'alias',
      name: 'heading-text',
      value: 'base-200',
    },
    {
      __type: 'alias',
      name: 'input-background',
      value: 'base-900',
    },
    {
      __type: 'alias',
      name: 'input-text',
      value: 'base-200',
    },
    {
      __type: 'alias',
      name: 'page-gradient-stop',
      value: 'base-75',
    },
    {
      __type: 'alias',
      name: 'loyalty-program-progressbar-track',
      value: 'base-300',
    },
    { __type: 'alias', name: 'menu-balance-background', value: 'base-100' },
    {
      __type: 'alias',
      name: 'menu-balance-visibility-toggler',
      value: 'base-300',
    },
    {
      __type: 'alias',
      name: 'menu-balance-visibility-toggler-hover',
      value: 'base-500',
    },
    { __type: 'alias', name: 'menu-loyalty-background', value: 'base-100' },
    { __type: 'alias', name: 'my-identity-dimmed', value: 'base-650' },
    { __type: 'alias', name: 'nav', value: 'base-300' },
    { __type: 'alias', name: 'nav-active', value: 'base-300' },
    { __type: 'alias', name: 'nav-highlight', value: 'negative-400' },
    { __type: 'alias', name: 'nav-hover', value: 'base-300' },
    { __type: 'alias', name: 'nav-item', value: 'base-700' },
    { __type: 'alias', name: 'page-nav-heading', value: 'info-100' },
    { __type: 'alias', name: 'picked-game', value: 'info-100' },
    { __type: 'alias', name: 'primary', value: 'positive-500' },
    { __type: 'alias', name: 'primary-active', value: 'positive-300' },
    { __type: 'alias', name: 'primary-hover', value: 'positive-600' },
    {
      __type: 'alias',
      name: 'registration-progress-background',
      value: 'base-75',
    },
    {
      __type: 'alias',
      name: 'registration-progress-active-background',
      value: 'positive-500',
    },
    { __type: 'alias', name: 'text-disabled', value: 'base-800' },
    { __type: 'alias', name: 'unseen-inventory-border', value: 'info-400' },
    { __type: 'alias', name: 'unseen-promotion-border', value: 'info-400' },
    { __type: 'alias', name: 'wallet-progress-background', value: 'base-75' },
    {
      __type: 'alias',
      name: 'finland-welcome-offer-shadow',
      value: 'finland-welcome-offer-text',
    },
    {
      __type: 'alias',
      name: 'canada-welcome-offer-shadow',
      value: 'canada-welcome-offer-text',
    },
    // VARIANTS
    //  - ALTERNATIVE
    {
      __type: 'alias',
      name: 'heading-text',
      value: 'base-75',
      variant: 'alternative',
    },
    {
      __type: 'alias',
      name: 'input-background',
      value: 'base-75',
      variant: 'alternative',
    },
    {
      __type: 'alias',
      name: 'input-text',
      value: 'base-900',
      variant: 'alternative',
    },
    {
      __type: 'alias',
      name: 'my-account-body-small',
      value: 'base-75',
      variant: 'alternative',
    },
    {
      __type: 'alias',
      name: 'my-account-body-large',
      value: 'base-100',
      variant: 'alternative',
    },
    {
      __type: 'alias',
      name: 'my-account-body-text',
      value: 'base-75',
      variant: 'alternative',
    },

    // LEGACY
    { name: 'cardBackground', value: '#ffffff' },
    { name: 'gameTileFeatured', value: '#371b43' },
    { name: 'gameTileHighestWin', value: '#009f66' },
    { name: 'gameTileNewest', value: '#1fb6be' },
    { name: 'gameTileNewestProvider', value: '#fdac00' },
    { name: 'gameTileNewestTable', value: '#3c3abb' },
    { name: 'gameTilePopular', value: '#fa5847' },
    { name: 'conditionalGameTileText', value: '#ffffff' },
    { name: 'htmlContent', value: '#43484F' },
    { name: 'info', value: '#1fb6be' },
    { name: 'input', value: '#c9c9c9' },
    { name: 'inputBackground', value: '#142737' },
    { name: 'inputDisabled', value: '#e8e7e8' },
    { name: 'inputHover', value: '#a9a9a9' },
    { name: 'inputText', value: '#43484F' },
    { name: 'kewlLinkIconBackground', value: '#ffffff' },
    { name: 'kewlLinkIconColor', value: '#17061d' },
    { name: 'scrollBarThumb', value: '#0D989F' },
    { name: 'scrollBarTrack', value: '#371b43' },
    { name: 'secondary', value: '#13838C' },
    { name: 'secondaryButton', value: '#1EB6BE' },
    { name: 'secondaryActive', value: '#0D989F' },
    { name: 'secondaryHover', value: '#28cad3' },
    { name: 'selectText', value: '#43484F' },
    { name: 'success', value: '#009f66' },
    { name: 'textDanger', value: '#fa5847' },
    { name: 'warning', value: '#ffc956' },
  ],

  fonts: [
    {
      name: 'head',
      value: 'Poppins, Helvetica, Arial, sans-serif',
    },
    {
      name: 'body',
      value: 'Poppins, Helvetica, Arial, sans-serif',
    },
  ],

  fontSizes: [
    { value: 10 },
    { value: 12 },
    { value: 14 },
    { value: 16 },
    { value: 18 },
    { value: 20 },
    { value: 24 },
    { value: 32 },
    { value: 48 },
  ],

  radii: [{ value: 4 }, { value: 8 }],

  space: [
    { value: 8 },
    { value: 16 },
    { value: 24 },
    { value: 32 },
    { value: 40 },
    { value: 64 },
    { value: 80 },
    { value: 112 },
    { value: 160 },
  ],

  shadows: [
    { value: '0 3px 4px 0 rgba(0, 0, 0, 0.1)' },
    { value: '0 7px 14px 0 rgba(0, 0, 0, 0.1)' },
    { value: '0 7px 40px 0 rgba(0, 0, 0, 0.1)' },
  ],

  variants: [
    {
      name: 'bg-pattern-default',
      value: '/images/spinlira-background-pattern-d-shape.svg',
    },
    {
      name: 'bg-pattern-d-shape',
      value: '/images/spinlira-background-pattern-d-shape.svg',
    },
    {
      name: 'bg-pattern-stripe-small',
      value: '/images/spinlira-background-pattern-stripe-small.svg',
    },
    {
      name: 'bg-pattern-stripe-large',
      value: '/images/spinlira-background-pattern-stripe.svg',
    },
    {
      name: 'buttons',
      value: {
        primary: {
          borderRadius: '4px',
          color: '#142737',
          bg: '#0094FF',
          border: 'none',
          '&:hover:enabled, &:focus': {
            bg: '#5DBBFF',
            boxShadow: 1,
          },
          '&:active:enabled': {
            bg: '#5DBBFF',
            boxShadow: 0,
          },
          '&:disabled': {
            bg: '#004B81',
            color: 'hsla(205, 100%, 68%, 0.6)',
            cursor: 'not-allowed',
          },
        },
        'secondary-outlined': {
          borderRadius: '4px',
          color: '#93B9E5',
          border: '1px solid #93B9E5',
          bg: 'transparent',
          padding: '0.76em 1.1em',
          '&:hover:enabled, &:focus': {
            color: '#5DBBFF',
            borderColor: '#5DBBFF',
          },
          '&:active:enabled': {
            color: '#5DBBFF',
            borderColor: '#5DBBFF',
          },
          '&:disabled': {
            bg: 'transparent',
            borderColor: '#004B81',
            color: '#004B81',
            cursor: 'not-allowed',
          },
        },
      },
    },
  ],
})
