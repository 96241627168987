// TODO: rename folder to "configuration" when reducer is gone

import * as R from 'ramda'
import * as ReactRedux from 'react-redux'
import * as React from 'react'
import PropTypes from 'prop-types'
import stringify from 'fast-stringify'
import { Helmet } from 'react-helmet-async'

import * as configuration from '../configuration'
import * as Constants from '../constants'
import * as Cookies from '../cookies-module'
import * as Http from '../http-module'
import * as Session from '../session'

import * as State from './state'
import { useClientTypeObserver } from './use-client-type-observer'
import { useDevicePixelRatioObserver } from './use-device-pixel-ratio-observer'

function noop() {}

const ClientConfigContext = React.createContext([{}, noop])

// Due to issues witl locale parsing some players might have incorrect values
// in their cookies. This is a lame way to wipe it which doesn’t take into
// account invalid country codes.
// TODO: Add proper country code validation (in app or in GraphQL itself)
function normalizeCountryCode(countryCode) {
  if (countryCode == null) {
    return null
  }

  if (countryCode.length > 2) {
    return null
  }

  return countryCode
}

export function Provider(props) {
  const reduxDispatch = ReactRedux.useDispatch()
  const request = Http.useRequest()
  const host = request?.headers['host']

  const gqlUrl = host?.includes('local')
    ? process.env.GRAPHQL_URL
    : `https://gql.${host
        ?.split('.')
        ?.slice(1)
        ?.join('.')}`

  const locale = Http.useLocale()
  const query = Http.useParsedQuery()
  const clientCountryCode = Http.useRequestHeader('CF-IPCountry')
  const [clientType, setClientType] = Cookies.useCookie(
    Constants.CookieKeys.CLIENT_TYPE
  )
  const [countryCode, setCountryCode] = Cookies.useCookie(
    Constants.CookieKeys.COUNTRY_CODE
  )
  const [language, setLanguage] = Cookies.useCookie(
    Constants.CookieKeys.LANGAUGE
  )
  const [devicePixelRatio, setDevicePixelRatio] = Cookies.useCookie(
    Constants.CookieKeys.DEVICE_PIXEL_RATIO
  )
  const authenticated = ReactRedux.useSelector(state =>
    Session.isAuthenticated(state.session)
  )

  const playerLanguage =
    authenticated || query.confirm_locale
      ? locale.language || language
      : language

  const playerCountryCode =
    authenticated || query.confirm_locale
      ? locale.region || normalizeCountryCode(countryCode)
      : normalizeCountryCode(countryCode)

  const [state, dispatch] = React.useReducer(
    State.reducer,
    process.browser ? window.__CLIENT_CONFIG__ : State.INITIAL_STATE,
    process.browser
      ? R.identity
      : // Since server-side rendering is done in one pass, we are not able to
        // `dispatch` updates during render as they will not be applied.
        // Therefore we use this hacky state initializer, which dispatches
        // actions manually to have data we need to be applied on server in
        // state beforehand.
        initialState =>
          R.reduce(
            State.reducer,
            initialState,
            R.reject(R.isNil, [
              State.updateClientType(clientType),
              State.updateClientCountryCode(locale.region || clientCountryCode),
              State.updateDevicePixelRatio(devicePixelRatio),
              State.updateGraphqlUrl(gqlUrl),
              State.updateLanguage(playerLanguage),
              State.updatePlayerCountryCode(playerCountryCode),
              State.updatePreferredLanguages(request),
            ])
          )
  )

  const updateClientType = React.useCallback(
    nextClientType => {
      dispatch(State.updateClientType(nextClientType))

      // Keep Redux state in sync
      // TODO: Remove when Redux is gone
      reduxDispatch(configuration.updateClientType(nextClientType))
    },
    [reduxDispatch]
  )

  const updateDevicePixelRatio = React.useCallback(
    nextDevicePixelRatio => {
      dispatch(State.updateDevicePixelRatio(nextDevicePixelRatio))

      // Keep Redux state in sync
      // TODO: Remove when Redux is gone
      reduxDispatch(configuration.updateDevicePixleRatio(nextDevicePixelRatio))
    },
    [reduxDispatch]
  )

  useClientTypeObserver(updateClientType)
  useDevicePixelRatioObserver(updateDevicePixelRatio)

  setClientType(State.getClientType(state), {
    httpOnly: false,
    maxAge: 365 * 24 * 60 * 60,
    path: '/',
  })

  setDevicePixelRatio(State.getDevicePixelRatio(state), {
    httpOnly: false,
    maxAge: 365 * 24 * 60 * 60,
    path: '/',
  })

  setCountryCode(State.getPlayerCountryCode(state), {
    httpOnly: false,
    maxAge: 30 * 24 * 60 * 60,
    path: '/',
  })

  setLanguage(State.getLanguage(state), {
    httpOnly: false,
    maxAge: 30 * 24 * 60 * 60,
    path: '/',
  })

  // HACK: `useMemo` instead of `useEffect` to make it work during SSR
  React.useMemo(() => {
    // Keep Redux state in sync; needed for @rushplay/api-client
    // TODO: Remove when Redux is gone
    const clientCountryCode = State.getClientCountryCode(state)
    reduxDispatch(configuration.updateCountryCode(clientCountryCode))
  }, [reduxDispatch, state])

  const value = React.useMemo(() => [state, dispatch], [state, dispatch])

  return (
    <React.Fragment>
      <Helmet>
        <script>{`window.__CLIENT_CONFIG__ = ${stringify(state)}`}</script>
      </Helmet>
      <ClientConfigContext.Provider value={value}>
        {props.children}
      </ClientConfigContext.Provider>
    </React.Fragment>
  )
}

Provider.propTypes = {
  children: PropTypes.node,
}

export function useContext() {
  return React.useContext(ClientConfigContext)
}

export function useClientType() {
  const [state] = useContext()
  return State.getClientType(state)
}

export function useCountryCode(confirmed) {
  const [state, dispatch] = useContext()
  const clientCountryCode = State.getClientCountryCode(state)
  const playerCountryCode = State.getPlayerCountryCode(state)

  const value = R.defaultTo(
    confirmed ? null : clientCountryCode,
    playerCountryCode
  )
  const setValue = React.useCallback(
    countryCode => {
      dispatch(State.updatePlayerCountryCode(countryCode))
    },
    [dispatch]
  )

  return [value, setValue]
}

export function useDevicePixelRatio() {
  const [state] = useContext()
  return State.getDevicePixelRatio(state)
}

export function useGraphqlUrl() {
  const [state] = useContext()

  if (process.browser) {
    return State.getGraphqlUrl(state)
  }

  return process.env.GRAPHQL_INTERNAL_URL || State.getGraphqlUrl(state)
}

export function useLanguage() {
  const [state, dispatch] = useContext()

  const value = State.getLanguage(state)
  const setValue = React.useCallback(
    language => {
      dispatch(State.updateLanguage(language))
    },
    [dispatch]
  )

  return [value, setValue]
}

export function usePreferredLanguages() {
  const [state] = useContext()
  return State.getPreferredLanguages(state)
}

export function useLocale() {
  const [state, dispatch] = useContext()

  const value = State.getLocale(state)
  const setValue = React.useCallback(
    (language, region) => {
      dispatch(State.updateLocale(language, region))
    },
    [dispatch]
  )

  return [value, setValue]
}

export function useBasename() {
  const [{ language, region }] = useLocale()

  if (language && region) {
    return `/${language}-${region}`
  }

  return '/'
}
