import * as React from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { Provider as ReduxProvider } from 'react-redux'

import * as common from '@rushplay/common'

import * as ClientConfiguration from './client-configuration'
import * as DegbugTranslations from './dev-tools/show-translation-keys'
import * as ServerConfiguration from './server-configuration'
import * as Cookies from './cookies-module'
import * as Http from './http-module'
import * as Suspense from './suspense'
import * as Translations from './translations'
import * as Theming from './theming'
import PropTypes from './prop-types'
import { GraphqlProvider } from './graphql-provider'
import { PendingTransactionsProvider } from './pending-transactions'
import { Router } from './router'
import { SeonProvider } from './seon-provider'

/**
 * Top-level provider composing providers necessary for application runtime
 * @param {Object} props
 */
export function GlobalProvider(props) {
  return (
    <Suspense.Provider>
      <HelmetProvider context={props.context}>
        <ReduxProvider store={props.store}>
          <Http.Provider request={props.request} response={props.response}>
            <Cookies.Provider cookies={props.cookies}>
              <SeonProvider>
                <ClientConfiguration.Provider>
                  <Router context={props.context}>
                    <GraphqlProvider ssrCache={props.ssrCache}>
                      <ServerConfiguration.Provider>
                        <Theming.Provider>
                          <common.TimestampProvider>
                            <DegbugTranslations.Provider>
                              <Translations.Provider>
                                <PendingTransactionsProvider>
                                  {props.children}
                                </PendingTransactionsProvider>
                              </Translations.Provider>
                            </DegbugTranslations.Provider>
                          </common.TimestampProvider>
                        </Theming.Provider>
                      </ServerConfiguration.Provider>
                    </GraphqlProvider>
                  </Router>
                </ClientConfiguration.Provider>
              </SeonProvider>
            </Cookies.Provider>
          </Http.Provider>
        </ReduxProvider>
      </HelmetProvider>
    </Suspense.Provider>
  )
}

GlobalProvider.propTypes = {
  basename: PropTypes.string,
  children: PropTypes.node,
  context: PropTypes.object,
  cookies: PropTypes.object,
  location: PropTypes.string,
  request: PropTypes.object,
  response: PropTypes.object,
  ssrCache: PropTypes.func,
  store: PropTypes.reduxStore.isRequired,
}
