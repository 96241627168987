import PropTypes from 'prop-types'

import styled from '@emotion/styled'

export const FieldLabel = styled.label`
  font-size: 12px;
  font-weight: 500;
  padding-bottom: 8px;
  line-height: 14px;
  display: block;
`

FieldLabel.propTypes = {
  disabled: PropTypes.bool,
}
