import * as R from 'ramda'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import PropTypes from 'prop-types'

import * as i18n from '@rushplay/i18n'
import { toAmount } from '@rushplay/common'

import * as DebugTranslations from '../dev-tools/show-translation-keys'
import * as ServerConfiguration from '../server-configuration'

// TODO: Handle case in @rushplay/i18n
function createKeyResolver(brand, language, showTranslateKeys) {
  return function keyResolver(keys, key) {
    if (typeof key === 'string') {
      // A workaround to display translation keys
      // Adds an extra ':' to the key when 'showTranslateKeys' is true, preventing it from being translated
      const normalizedKey = showTranslateKeys
        ? `${language}::${brand}.${key}`
        : `${language}:${brand}.${key}`
      const caseInsensitiveKey = R.toLower(normalizedKey)

      if (R.includes(caseInsensitiveKey, keys)) {
        return caseInsensitiveKey
      }

      return normalizedKey
    }
  }
}

function stateResolver(state) {
  return state.i18n
}

export function Provider(props) {
  const configuration = ServerConfiguration.useContext()
  const { debug } = DebugTranslations.useContext()

  const hostName = ReactRedux.useSelector(
    state => state.configuration?.hostName
  )

  const translationsUrl = React.useMemo(() => {
    const cdnUrl = `https://cdn.${hostName}/${configuration.cdnPrefix}`
    const filename = `${configuration.brand}_${configuration.locale.code}`
    return `${cdnUrl}/locales/${filename}.json`
  }, [configuration])

  const processors = React.useMemo(() => {
    return {
      currency(value, data) {
        const currency = configuration.currencies.find(
          currency => currency.code === data.currencyCode
        )

        if (process.env.NODE_ENV !== 'production') {
          if (currency == null) {
            // eslint-disable-next-line no-console
            console.error(`"${data.currencyCode}" is an unknown currency`)
          }
        }

        return toAmount({ currency, locale: data.locale }, value)
      },
    }
  }, [configuration.currencies])

  const variableResolver = React.useCallback(() => {
    return {
      brandEmail: configuration.supportEmail,
      brandName: 'Spinlira Casino',
      currencyCode: configuration.currency.code,
      locale: configuration.locale.slug,
    }
  }, [configuration])

  return (
    <i18n.TranslationProvider
      keyResolver={createKeyResolver(
        configuration.brand,
        R.toLower(configuration.locale.code),
        debug
      )}
      language={R.toLower(configuration.locale.code)}
      processors={processors}
      stateResolver={stateResolver}
      url={translationsUrl}
      variableResolver={variableResolver}
    >
      {props.children}
    </i18n.TranslationProvider>
  )
}

Provider.propTypes = {
  children: PropTypes.element,
  language: PropTypes.string,
  url: PropTypes.string,
}
