import * as R from 'ramda'
import { bind } from 'redux-effects'

import * as Analytics from '@rushplay/analytics'
import * as api from '@rushplay/api-client'

import * as player from './player'
import * as Cookies from './cookies'

export * from '@rushplay/analytics'

// Constants
const OPEN_PROMOTION_MENU = 'analytics/OPEN_PROMOTION_MENU'
const CLAIM_PROMOTION = 'analytics/CLAIM_PROMOTION'
const BROWSE_PROMOTION = 'analytics/BROWSE_PROMOTION'
const CASH_VISIT = 'analytics/CASH_VISIT'
const CASH_OUT = 'analytics/CASH_OUT'

const BRITE_DEPOSIT = 'PiqkycBriteDeposit'

// Actions
export function openPromotionMenu(payload) {
  return Analytics.queueEvent({
    type: OPEN_PROMOTION_MENU,
    payload,
  })
}

export function claimPromotion(payload) {
  return Analytics.queueEvent({
    type: CLAIM_PROMOTION,
    payload,
  })
}

export function browsePromotion(payload) {
  return Analytics.queueEvent({
    type: BROWSE_PROMOTION,
    payload,
  })
}

export function cashVisit(payload) {
  return Analytics.queueEvent({
    type: CASH_VISIT,
    payload,
  })
}

export function cashOut(payload) {
  return Analytics.queueEvent({
    type: CASH_OUT,
    payload,
  })
}

function present(values) {
  return R.none(R.isNil, values)
}

export const gtmEvents = {
  [Analytics.AUTHENTICATED]: (action, prevState, nextState) => {
    const uid = player.getUsername(nextState.player)
    const affiliateid = player.getAffiliateId(nextState.player)

    if (R.not(present([uid]))) {
      return null
    }

    return {
      event: 'login',
      user: {
        uid,
        affiliateid,
      },
    }
  },

  [Analytics.DEPOSIT_AMOUNT_SELECTED]: (action, prevState, nextState) => {
    const uid = player.getUsername(nextState.player)
    const affiliateid = player.getAffiliateId(nextState.player)
    const valueCents = action.payload

    if (R.not(present([valueCents]))) {
      return null
    }

    return {
      event: 'amountSelected',
      deposit: { value: valueCents / 100 },
      user: { affiliateid, uid },
    }
  },

  [Analytics.DEPOSIT_PAYMENT_METHOD_SELECTED]: (
    action,
    prevState,
    nextState
  ) => {
    const uid = player.getUsername(nextState.player)
    const affiliateid = player.getAffiliateId(nextState.player)
    const method = action.payload

    if (R.not(present([method]))) {
      return null
    }

    return {
      event: 'paymentMethodSelected',
      deposit: {
        method,
      },
      user: { affiliateid, uid },
    }
  },

  [Analytics.DEPOSIT_TRANSACTION_COMPLETED]: (action, prevState, nextState) => {
    const uid = player.getUsername(nextState.player)
    const affiliateid = player.getAffiliateId(nextState.player)
    const depositAttempts = player.getDepositAttempts(nextState.player)
    const valueCents = player.getLastDepositAmount(nextState.player)
    const transactionId = player.getLastDepositTransactionId(nextState.player)
    const method = action.payload.depositMethod
    const status = action.payload.transactionStatus

    if (R.not(present([depositAttempts, valueCents, uid]))) {
      if (method === BRITE_DEPOSIT) {
        return {
          event: 'transactionCompleted',
          deposit: {
            method,
            status,
          },
        }
      }
      return null
    }

    return {
      event: 'transactionCompleted',
      deposit: {
        count: depositAttempts,
        id: transactionId,
        value: status === 'successful' ? valueCents / 100 : null,
        method,
        status,
      },
      user: {
        affiliateid,
        uid,
      },
    }
  },

  [Analytics.PAGE_VIEWED]: action => ({
    event: 'pageview',
    page: {
      path: action.payload.path,
      title: action.payload.title,
    },
  }),

  [Analytics.PLAY_FOR_FUN]: (action, prevState, nextState) => {
    const affiliateid = player.getAffiliateId(nextState.player)
    return {
      event: 'playforfun-enter',
      game: {
        title: action.payload.title,
      },
      user: {
        affiliateid,
        logged: action.payload.logged,
      },
    }
  },

  [Analytics.REGISTRATION_STARTED]: (action, prevState, nextState) => {
    const affiliateid = player.getAffiliateId(nextState.player)

    return {
      event: 'registrationStarted',
      user: {
        affiliateid,
      },
      registrationType: R.path(['payload', 'registrationType'], action),
    }
  },

  [Analytics.REGISTERED]: (action, prevState, nextState) => {
    const uid = player.getUsername(nextState.player)
    const affiliateid = player.getAffiliateId(nextState.player)

    if (R.not(present([uid]))) {
      return null
    }

    return {
      event: 'nrc',
      user: {
        uid,
        affiliateid,
      },
      registrationType: R.path(['payload', 'registrationType'], action),
    }
  },

  [Analytics.BONUS_CLAIMED]: (action, prevState, nextState) => {
    const currency = player.getCurrency(nextState.player)

    if (R.not(present([currency]))) {
      return null
    }

    return {
      event: 'bonus',
      bonus: {
        value: R.divide(action.payload.value, 100),
        currency: player.getCurrency(nextState.player),
        claimed: action.payload?.claimed ? 'yes' : 'no',
      },
    }
  },

  [OPEN_PROMOTION_MENU]: action => ({
    event: 'promotion-menu',
    page: {
      path: action.payload.path,
      title: action.payload.title,
    },
  }),

  [CLAIM_PROMOTION]: action => ({
    event: 'promotion-claim',
    promotion: {
      title: action.payload.title,
      claimed: action.payload?.claimed,
      type: action.payload.type,
    },
  }),

  [BROWSE_PROMOTION]: action => ({
    event: 'promotion-browse',
    tab: {
      title: action.payload.title,
      destination: action.payload.destination,
    },
  }),

  [CASH_VISIT]: action => ({
    event: 'interaction-visit',
    eventCategory: 'cash-interaction',
    eventLabel: 'cash-visit',
    eventAction: action.payload.eventAction,
    page: {
      title: action.payload.title,
      path: action.payload.path,
    },
  }),

  [CASH_OUT]: (action, prevState, nextState) => {
    const currency = player.getCurrency(nextState.player)
    const uid = player.getUsername(nextState.player)
    const claimableBalanceCents = player.getClaimableBalanceCents(
      nextState.player
    )

    if (R.not(present([currency, uid, claimableBalanceCents]))) {
      return null
    }

    return {
      event: 'interaction-out',
      eventCategory: 'cash-interaction',
      eventLabel: 'cash-out',
      user: {
        uid,
      },
      cash: {
        currency,
        value: R.divide(claimableBalanceCents, 100),
      },
      page: {
        title: action.payload.title,
        path: action.payload.path,
      },
    }
  },
}

export function analyticsRegistration() {
  return api.fetchNotificationsByKind('analytics', {
    success: res => {
      const signUpNotification = R.find(R.propEq('event', 'signup'), res.value)

      if (signUpNotification) {
        return api.deleteNotification(signUpNotification.id, {
          success: () =>
            bind(Cookies.get('REGISTER_VARIANT'), registrationType =>
              Analytics.register({ registrationType })
            ),
          version: 1,
        })
      }
    },
    version: 1,
  })
}

export const mapsEvents = {
  [Analytics.DEPOSIT_TRANSACTION_COMPLETED]: (action, prevState, nextState) => {
    const btag = player.getBtag(nextState.player)
    const valueCents = player.getLastDepositAmount(nextState.player)
    const playerId = player.getMapsPlayerId(nextState.player)
    const transactionStatus = action?.payload?.transactionStatus

    if (transactionStatus !== 'successful') {
      return {}
    }

    if (R.not(present([valueCents, playerId]))) {
      return null
    }

    return {
      actionName: 'deposit',
      btag,
      playerId,
      deposit: {
        value: valueCents / 100,
      },
    }
  },
  [Analytics.REGISTERED]: (action, prevState, nextState) => {
    const btag = player.getBtag(nextState.player)
    const playerId = player.getMapsPlayerId(nextState.player)

    if (R.not(present([playerId]))) {
      return null
    }

    return {
      actionName: 'registration',
      btag,
      playerId,
    }
  },
}
