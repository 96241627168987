import * as R from 'ramda'
import * as React from 'react'
import PropTypes from 'prop-types'

import * as Emotion from '@emotion/core'
import css from '@styled-system/css'
import styled from '@emotion/styled'
import { useI18n } from '@rushplay/i18n'

import * as icons from './icons'

function borderColor(props) {
  if (props.focused) {
    return 'secondary'
  }

  if (props.valid) {
    return 'success'
  } else if (props.invalid) {
    return 'danger'
  } else if (props.disabled) {
    return 'gray'
  } else {
    return 'rgba(255, 255, 255, 0.20)'
  }
}

function hover(props) {
  if (props.valid || props.invalid || props.disabled || props.focused) {
    return null
  } else {
    return 'inputHover'
  }
}

const Wrapper = styled.label`
  ${props =>
    css({
      alignItems: 'center',
      display: 'flex',
      color: props.focused ? 'secondary' : 'white',
      backgroundColor: props.disabled ? 'inputDisabled' : 'inputBackground',
      border: '1px solid',
      borderColor: borderColor(props),
      borderRadius: 0,
      boxShadow: props.focused ? 0 : 'none',
      fontSize: 3,
      flexGrow: 1,
      flexShrink: 1,
      overflow: 'hidden',
      '&:hover': {
        borderColor: hover(props),
      },
    })}
`

const StyledSelect = styled.select`
  ${css({
    backgroundColor: 'inputBackground',
    border: 0,
    color: 'white',
    flexGrow: 1,
    flexShrink: 1,
    fontFamily: 'body',
    fontSize: 3,
    mr: '-30px',
    paddingBottom: '0.5em',
    paddingLeft: '15px',
    paddingTop: '0.5em',
    width: '100%',
    '&:focus': { outline: 'none' },
    appearance: 'none',
  })}

  &:disabled {
    cursor: not-allowed;
  }

  // hack to change the background-color that's added by autofill
  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    ${props => Emotion.css`
      -webkit-box-shadow: 0 0 0 30px ${props.theme.colors['input-background']} inset !important;
    `}
  }
`

const CaretDown = styled.div`
  font-size: 1.5em;
  ${css({ color: 'gray' })};
  pointer-events: none;
  width: 30px;
`

export function Select(props) {
  const i18n = useI18n()
  const inputRef = React.useRef(null)
  const [focused, setFocused] = React.useState(props.autoFocus)

  const value = props.value && props.value.toString()
  const valid = props.valid && props.visited
  const invalid = !props.valid && props.visited

  React.useEffect(() => {
    // Handle focus state for wrapper styles
    if (focused && inputRef.current) {
      inputRef.current.focus()
      /**
       * Because of custom focus handling, re-render of component happens before the
       * onChange event. This causes the value of the field to fallback to the initial value
       * on auto-complete/fill events.
       * By calling the onChange with the value of the component before it's focused we ensure it's the correct value.
       * The side-effect though, is that onChange is fired twice.
       */
      props.onChange({ target: inputRef.current.control })
    }
  }, [focused, inputRef])

  function handleBlur() {
    setFocused(false)
    props.onBlur()
  }

  return (
    <Wrapper
      empty={R.isEmpty(props.value)}
      valid={valid}
      invalid={invalid}
      focused={focused}
      disabled={props.disabled}
      ref={inputRef}
    >
      <StyledSelect
        autoComplete={props.autoComplete}
        autoFocus={props.autoFocus}
        disabled={props.disabled}
        value={value}
        data-testid={`${props.name}.select-field`}
        onBlur={handleBlur}
        onChange={props.onChange}
        onFocus={() => setFocused(true)}
      >
        {props.options &&
          R.map(
            option => (
              <option key={option.value} value={option.value}>
                {props.contentTranslated
                  ? option.value
                  : i18n.translate(option.label)}
              </option>
            ),
            props.options
          )}
      </StyledSelect>
      <CaretDown>
        <icons.ExpandMore />
      </CaretDown>
    </Wrapper>
  )
}

Select.defaultProps = {
  onBlur: () => {},
}

Select.propTypes = {
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.bool,
  contentTranslated: PropTypes.bool,
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  options: PropTypes.array,
  valid: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  visited: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
}
